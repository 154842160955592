
import { OnboardingProgressIndicator } from "./OnboardingProgressIndicator.js";


export const OnboardingProcess = () => {

    return (

    <div className="upload-function-container">
    <div className="upload-function-box">
    <button
        className="close-button"
        onClick={() => window.location.href = '/'}
    >
        ×
    </button>
    <p className="text-overlay-upload">{"Get Started!"}</p>
    <p className="text-instructions-onboarding">
        {"Once you are ready, let's create your portraits!"}
        </p>
    <div className="checklist-instructions-onboarding">
    <label for="item1">
        <input type="checkbox" id="item1" checked onClick={(e) => e.preventDefault()} />
        {" Log in with your Google Account"}
    </label>
    <br />
    <label for="item2">
        <input type="checkbox" id="item2" checked onClick={(e) => e.preventDefault()} />
        {" Pay £19.99"}
    </label>
    <br />
    <label for="item3">
        <input type="checkbox" id="item3" checked onClick={(e) => e.preventDefault()} />
        {" Upload 30 selfies"}
    </label>
    <br />
    <label for="item4">
        <input type="checkbox" id="item4" checked onClick={(e) => e.preventDefault()} />
        {" Receive 25 unique AI portraits in 48 hours"}
    </label>
    </div>

    <button
        onClick={() => window.location.assign('/login')}
        >
        <div className="onboarding_button_layout">
            <span>Ready!</span>
        </div>
    </button>
    <div className="progress-indicator-container">
        <OnboardingProgressIndicator currentStep={3} />
    </div>
    </div>
    </div>
    );
  };
