// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJA8no2milYdeQNsiCPwqHbSHzwjU-plI",
  authDomain: "portrait-41903.firebaseapp.com",
  projectId: "portrait-41903",
  storageBucket: "portrait-41903.appspot.com",
  messagingSenderId: "740398190212",
  appId: "1:740398190212:web:da96dae4269a6b376a3cd1",
  measurementId: "G-BM2XXBWB5H"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage();
//const analytics = getAnalytics(app);

// Export function to initialize Firebase

export const initFirebase = () => {
    return app;
}