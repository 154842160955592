import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import headerImg1 from "../assets/img/header-img-robot_wo_feet.svg";
import headerImg2 from "../assets/img/header-img-svg-just-canvas-floating.svg";
import { HashLink } from 'react-router-hash-link';

import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const Banner = () => {
  const [text, setText] = useState('');
  var ReactRotatingText = require('react-rotating-text');


  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            {/* <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}> */}
                <span className="tagline">AI Portrait Generator</span>
                <h1>{`AI, show me in a`}</h1>
                <h1><ReactRotatingText items={['photoshoot', 'new style', 'painting']}/><span className="wrap">{text}</span></h1>
                    <p>
                      Using the latest in artificial intelligence, we create stunning portraits that are completely unique to you. 
                    </p>
                    <p>
                      Our AI will create a one-of-a-kind work of art that captures your essence perfectly in a selection of our artful styles.
                    </p>
                    <button
                    //   onClick={() => {
                    //   const element = document.getElementById('sign_up');
                    //   element.scrollIntoView();
                    //   console.log('connect');
                    // }} style={{ textDecoration: 'none' }}>
                    onClick={() => window.location.assign('/step_one')} style={{ textDecoration: 'none' }}>
                      Get started! <ArrowRightCircle size={25} />
                    </button>
              {/* </div>}
            </TrackVisibility> */}
          </Col>
          <Col xs={12} md={6} xl={5}>
            {/* <TrackVisibility>
              {({ isVisible }) =>
              <div> */}
                {/* <div className={isVisible ? "animate__animated animate__zoomIn" : ""} style={{position: 'relative',top: '50px'}}> */}
                <div className="flying-robo-pos">
                  <img src={headerImg1} alt="Header Img" className="headerImg1"/>
                </div>
                {/* <div className={isVisible ? "animate__animated animate__zoomIn" : ""} style={{position: 'relative',top: '-300px', left: '50px'}}> */}
                <div className="flying-canvas-pos">
                  <img src={headerImg2} alt="Header Img2" className="headerImg2"/>
                </div>
              {/* </div>}
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
