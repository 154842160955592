import { initFirebase } from '../firebase/firebaseApp';
import { getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import { useEffect } from 'react';
import google_accounts_logo from "../assets/icons/google_accounts.png"

export const AuthPage = () => {

    const app = initFirebase()
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const [user,loading] = useAuthState(auth);

    useEffect(() => {
        if (user) {
          window.location.assign('/payment');
        }
      }, [user]);
    

    if (loading) {
        console.log(loading)
      }
  
  
  
      const signIn = async () => {
        const result = await signInWithPopup(auth, provider)
        console.log(result.user)
      };
  
      return (

        <div className="upload-function-container">
        <div className="upload-function-box">
        <button
          className="close-button"
          onClick={() => window.location.href = '/'}
        >
          ×
        </button>
        <p className="text-overlay-upload">{"Sign in"}</p>
        <p className="text-instructions-upload">{"Sign in with your Google Account to upload your selfies."}</p>
        <button onClick={signIn}>
            <div className="sign_in_button_layout">
                <img src={google_accounts_logo} alt="Google Accounts logo" className="google_account_icon" />
                <span >Sign In</span>
            </div>
        </button>    
        </div>
      </div>
        );
  };
