import React, { useState } from "react";

import sample_upload1 from "../assets/img/pictures/sample_uploads/pstr_1.jpg";
import sample_upload2 from "../assets/img/pictures/sample_uploads/pstr_2.jpg";
import sample_upload3 from "../assets/img/pictures/sample_uploads/pstr_3.jpg";
import sample_upload4 from "../assets/img/pictures/sample_uploads/pstr_4.jpg";
import sample_upload5 from "../assets/img/pictures/sample_uploads/pstr_5.jpg";


import { OnboardingProgressIndicator } from "./OnboardingProgressIndicator.js";

export const OnboardingPage = () => {

    const [currentImage, setCurrentImage] = useState(sample_upload1);

    const images = [
    sample_upload1,
    sample_upload2,
    sample_upload3,
    sample_upload4,
    sample_upload5,
    ];

    const cycleImage = () => {
    const currentIndex = images.indexOf(currentImage);
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentImage(images[nextIndex]);
    };

    return (

    <div className="upload-function-container">
    <div className="upload-function-box">
    <button
        className="close-button"
        onClick={() => window.location.href = '/'}
    >
        ×
    </button>
    <p className="text-overlay-upload">{"Get Started!"}</p>
    <p className="text-instructions-onboarding">
        {"AI portraits are based on 30 selfies. "}
        </p>
        <div className="image-instructions-onboarding" onClick={cycleImage}>
          <img src={currentImage} alt="image" />
          <p className="text-instructions-onboarding-subtitle">{"Click me for examples"}</p>
        </div>
    <button
        onClick={() => window.location.assign('/step_two')}
        >
        <div className="onboarding_button_layout">
            <span>Okay!</span>
        </div>
    </button>
    <div className="progress-indicator-container">
        <OnboardingProgressIndicator currentStep={1} />
    </div>
    </div>
    </div>
    );
  };
