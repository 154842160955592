import React, { useState } from "react";


export const OnboardingProgressIndicator = ({ currentStep }) => {
    
    return (
      <div className="progress-indicator">
        <div className={`progress-circle ${currentStep >= 1 ? "filled" : ""}`} />
        <div className={`progress-circle ${currentStep >= 2 ? "filled" : ""}`} />
        <div className={`progress-circle ${currentStep >= 3 ? "filled" : ""}`} />
      </div>
    );
  };