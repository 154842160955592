import { useEffect } from 'react';
import stripe_logo from "../assets/icons/stripe_logo.png"

export const StripePaymentPage = () => {
  const stripeUrl = 'https://buy.stripe.com/fZeg0o3fY1SQ3Kw7ss';
  // const stripeUrl = 'https://buy.stripe.com/test_4gwfZqeXz6Iudvq7ss';
  // const windowFeatures = 'height=600,width=600';

  const handleButtonClick = () => {
    // window.open(stripeUrl, '_blank', windowFeatures);
    window.open(stripeUrl, '_blank');
    window.close();
  }

  return (
    <div className="upload-function-container">
      <div className="upload-function-box">
        <button
          className="close-button"
          onClick={() => window.location.href = '/'}
        >
          ×
        </button>
        <p className="text-overlay-upload">{"Sign in"}</p>
        <p className="text-instructions-upload">{"Visit Stripe to pay."}</p>
        <button onClick={handleButtonClick}>
          <div className="sign_in_button_layout">
            <img src={stripe_logo} alt="Stripe logo" className="stripe_logo_icon" />
          </div>
        </button>
      </div>
    </div>
  );
};
