
import { OnboardingProgressIndicator } from "./OnboardingProgressIndicator.js";


export const OnboardingPageChecklist = () => {

    return (

    <div className="upload-function-container">
    <div className="upload-function-box">
    <button
        className="close-button"
        onClick={() => window.location.href = '/'}
    >
        ×
    </button>
    <p className="text-overlay-upload">{"Get Started!"}</p>
    <p className="text-instructions-onboarding">
        {"Make sure your pictures fit the checklist."}
        </p>
    <div className="checklist-instructions-onboarding">
    <label for="item1">
        <input type="checkbox" id="item1" checked onClick={(e) => e.preventDefault()} />
        {" Headshot-style selfies with plain background"}
    </label>
    <br />
    <label for="item2">
        <input type="checkbox" id="item2" checked onClick={(e) => e.preventDefault()} />
        {" Good lighting"}
    </label>
    <br />
    <label for="item3">
        <input type="checkbox" id="item3" checked onClick={(e) => e.preventDefault()} />
        {" Vary your expressions"}
    </label>
    <br />
    <label for="item4">
        <input type="checkbox" id="item4" checked onClick={(e) => e.preventDefault()} />
        {" Tilt your head in different directions"}
    </label>
    </div>

    <button
        onClick={() => window.location.assign('/step_three')}
        >
        <div className="onboarding_button_layout">
            <span>Okay!</span>
        </div>
    </button>
    <div className="progress-indicator-container">
        <OnboardingProgressIndicator currentStep={2} />
    </div>
    </div>
    </div>
    );
  };
