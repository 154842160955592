import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import twittericon from "../assets/img/twitter-full.svg"
import navIcon3 from "../assets/img/nav-icon3.svg";
import new_logo from '../assets/img/new-logo-text.svg';
import mailIcon from '../assets/img/mail-icon.svg';


export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={new_logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end my-5">
            <div className="social-icon">
              {/* <a href="#"><img src={navIcon1} alt="Icon" /></a> */}
              <a href="http://twitter.com/snapshop_photos"><img src={twittericon} alt="Icon" /></a>
              <a href="http://instagram.com/snapshop_photos"><img src={navIcon3} alt="Icon" /></a>
              <a href="mailto:hello@snapshop.photos"><img src={mailIcon} alt="Icon" /></a>
            </div>
            {/* <p>Copyright 2022. All Rights Reserved</p> */}
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
