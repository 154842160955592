import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import men_1 from "../assets/img/pictures/men/men_1.png";
import men_2 from "../assets/img/pictures/men/men_2.png";
import men_3 from "../assets/img/pictures/men/men_3.png";
import men_4 from "../assets/img/pictures/men/men_4.png";
import men_5 from "../assets/img/pictures/men/men_5.png";
import men_6 from "../assets/img/pictures/men/men_6.png";

import women_1 from "../assets/img/pictures/women/women_1.png";
import women_2 from "../assets/img/pictures/women/women_2.png";
import women_3 from "../assets/img/pictures/women/women_3.png";
import women_4 from "../assets/img/pictures/women/women_4.png";
import women_5 from "../assets/img/pictures/women/women_5.png";
import women_6 from "../assets/img/pictures/women/women_6.png";


import couples_1 from "../assets/img/pictures/couples/couples_1.png";
import couples_2 from "../assets/img/pictures/couples/couples_2.png";
import couples_3 from "../assets/img/pictures/couples/couples_3.png";
import couples_4 from "../assets/img/pictures/couples/couples_4.png";
import couples_5 from "../assets/img/pictures/couples/couples_5.png";
import couples_6 from "../assets/img/pictures/couples/couples_6.png";


import sample_upload1 from "../assets/img/pictures/sample_uploads/pstr_1.jpg";
import sample_upload2 from "../assets/img/pictures/sample_uploads/pstr_2.jpg";
import sample_upload3 from "../assets/img/pictures/sample_uploads/pstr_3.jpg";
import sample_upload4 from "../assets/img/pictures/sample_uploads/pstr_4.jpg";
import sample_upload5 from "../assets/img/pictures/sample_uploads/pstr_5.jpg";
import sample_upload6 from "../assets/img/pictures/sample_uploads/pstr_6.jpg";

import sample_photo1 from "../assets/img/pictures/sample_photos/sample_photo1.png";
import sample_photo2 from "../assets/img/pictures/sample_photos/sample_photo2.png";
import sample_photo3 from "../assets/img/pictures/sample_photos/sample_photo3.png";
import sample_photo4 from "../assets/img/pictures/sample_photos/sample_photo4.png";
import sample_photo5 from "../assets/img/pictures/sample_photos/sample_photo5.png";
import sample_photo6 from "../assets/img/pictures/sample_photos/sample_photo6.png";


import sample_painting1 from "../assets/img/pictures/sample_paintings/sample_painting1.png";
import sample_painting2 from "../assets/img/pictures/sample_paintings/sample_painting2.png";
import sample_painting3 from "../assets/img/pictures/sample_paintings/sample_painting3.png";
import sample_painting4 from "../assets/img/pictures/sample_paintings/sample_painting4.png";
import sample_painting5 from "../assets/img/pictures/sample_paintings/sample_painting5.png";
import sample_painting6 from "../assets/img/pictures/sample_paintings/sample_painting6.png";





export const Projects = () => {

  const men = [
    {
      title: "Noir Drawing",
      description: "Generated in a noir drawing style",
      imgUrl: men_1,
    },
    {
      title: "Cyberpunk",
      description: "See yourself in a stylised cyberpunk artpiece",
      imgUrl: men_2,
    },
    {
      title: "Photoshoot",
      description: "Professional headshots",
      imgUrl: men_3,
    },
    {
      title: "Impressionism",
      description: "As painted by the greats",
      imgUrl: men_4,
    },
    {
      title: "Movie",
      description: "Starring in your favourite movies",
      imgUrl: men_5,
    },
    {
      title: "Digital Art",
      description: "Painted in a contemporary style",
      imgUrl: men_6,
    },
  ];

  const women = [
    {
      title: "Restyle",
      description: "See yourself in a new style",
      imgUrl: women_1,
    },
    {
      title: "Restyle",
      description: "See yourself in a new style",
      imgUrl: women_2,
    },
    {
      title: "Digital Art",
      description: "Painted in a contemporary style",
      imgUrl: women_3,
    },
    {
      title: "Movie",
      description: "Starring in your favourite movies",
      imgUrl: women_4,
    },
    {
      title: "Restyle",
      description: "See yourself in a new style",
      imgUrl: women_5,
    },
    {
      title: "Movie",
      description: "Starring in your favourite movies",
      imgUrl: women_6,
    },
  ];

  const couples = [
    {
      title: "Digital Art",
      description: "Painted in a contemporary style",
      imgUrl: couples_1,
    },
    {
      title: "Movie",
      description: "See yourself in your favourite movies",
      imgUrl: couples_2,
    },
    {
      title: "Photoshoot",
      description: "Professional portraits",
      imgUrl: couples_3,
    },
    {
      title: "Digital Art",
      description: "Painted in a contemporary style",
      imgUrl: couples_4,
    },
    {
      title: "Movie",
      description: "See yourself in your favourite movies",
      imgUrl: couples_5,
    },
    {
      title: "Photoshoot",
      description: "Professional portraits",
      imgUrl: couples_6,
    },
  ];

  const sample_uploads = [
    {
      title: "Sample upload",
      description: "Simple selfies will do the trick",
      imgUrl: sample_upload1,
    },
    {
      title: "Sample upload",
      description: "Keep the camera parallel to your face",
      imgUrl: sample_upload2,
    },
    {
      title: "Sample upload",
      description: "Vary your expression",
      imgUrl: sample_upload3,
    },
    {
      title: "Sample upload",
      description: "Tilt your head left and right",
      imgUrl: sample_upload4,
    },
    {
      title: "Sample upload",
      description: "Change up the background and lighting",
      imgUrl: sample_upload5,
    },
    {
      title: "Sample upload",
      description: "And wear a few different outfits",
      imgUrl: sample_upload6,
    },
  ];


const result_photos = [
  {
    title: "Sample photo",
    description: "Mid-shot photoshoots",
    imgUrl: sample_photo1,
  },
  {
    title: "Sample photo",
    description: "Black and white photography",
    imgUrl: sample_photo2,
  },
  {
    title: "Sample photo",
    description: "Outfit changes",
    imgUrl: sample_photo3,
  },
  {
    title: "Sample photo",
    description: "Costumes and armor",
    imgUrl: sample_photo4,
  },
  {
    title: "Sample photo",
    description: "See yourself in bygone eras",
    imgUrl: sample_photo5,
  },
  {
    title: "Sample photo",
    description: "Try out new styles",
    imgUrl: sample_photo6,
  },
];

const result_painting = [
  {
    title: "Sample painting",
    description: "80s fantasy",
    imgUrl: sample_painting1,
  },
  {
    title: "Sample painting",
    description: "Baroque style",
    imgUrl: sample_painting2,
  },
  {
    title: "Sample painting",
    description: "Photorealistic drawing",
    imgUrl: sample_painting3,
  },
  {
    title: "Sample painting",
    description: "Digital art",
    imgUrl: sample_painting4,
  },
  {
    title: "Sample painting",
    description: "Impressionism",
    imgUrl: sample_painting5,
  },
  {
    title: "Sample painting",
    description: "Dark fantasy",
    imgUrl: sample_painting6,
  },
];

  return (
    <section className="project" id="samples">
      <Container>
        <Row>
          <Col size={12}>
            {/* <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}> */}
                <h2>Samples</h2>
                <p>We think that seeing is believing. <br></br> Upload simple selfies, receive 25 gorgeous portraits.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Sample uploads</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Result photos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Result paintings</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {/* <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}> */}
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          sample_uploads.map((sample_uploads, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...sample_uploads}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                          {
                            result_photos.map((result_photos, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...result_photos}
                                  />
                              )
                            })
                          }
                        </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          result_painting.map((result_painting, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...result_painting}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              {/* </div>}
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
